import L from "leaflet";

export default {
    "vehicles.get": (response, context) => {
        return response;
    },
    "segment.post": (response, context) => {
        return response;
    },
    "plan.post": (response, context) => {
        return response;
    },
    "plan-execute.post": (response, context) => {
        return response;
    },
    "isalive.get": (response, context) => {
        return response;
    },
    "pots.get": (response, context) => {
        return response;
    },
    "stations.get": (response, context) => {
        return response;
    },
    "geocoder.get": (response, context) => {
        return response;
    },
    "reversegeocoder.get": (response, context) => {
        return response;
    },
    "plans-download.post": (response, context) => {
        // Check response status
        if (response.status !== "OK") {
            throw new Error(`Request failed with status: ${response.status}`);
        }

        // Extract planned routes
        const plannedRoutes = response.plannedRoutes.map((route) => {
            return {
                id: route.id,
                start: {
                    lat: route.start.geoCoordinate.lat,
                    lon: route.start.geoCoordinate.lon,
                    stopName: route.start.stop.name,
                    plannedAt: route.start.plannedAt,
                },
                end: {
                    lat: route.end.geoCoordinate.lat,
                    lon: route.end.geoCoordinate.lon,
                    stopName: route.end.stop.name,
                    plannedAt: route.end.plannedAt,
                },
                stats: {
                    distanceMeters: route.stats.distanceMeters,
                    durationSeconds: route.stats.durationSeconds,
                    fare: route.stats.fare
                        ? {
                              price: route.stats.fare.price,
                              currency: route.stats.fare.currencyCode,
                              paymentMethods:
                                  route.stats.fare.paymentMethods || [],
                          }
                        : null,
                    uphillMeters: route.stats.uphillMeters,
                },
                segments: route.segments.map((segment) => {
                    const segmentDetails = {
                        id: segment.id,
                        transportMode: segment.transportMode,
                        start: {
                            lat: segment.start.geoCoordinate.lat,
                            lon: segment.start.geoCoordinate.lon,
                            stopName: segment.start.stop.name,
                            plannedAt: segment.start.plannedAt,
                        },
                        end: {
                            lat: segment.end.geoCoordinate.lat,
                            lon: segment.end.geoCoordinate.lon,
                            stopName: segment.end.stop.name,
                            plannedAt: segment.end.plannedAt,
                        },
                        stats: {
                            distanceMeters: segment.stats.distanceMeters,
                            durationSeconds: segment.stats.durationSeconds,
                            uphillMeters: segment.stats.uphillMeters,
                        },
                        geometry: segment.geometry,
                        feasibility: segment.feasibility,
                        transferType: segment.transferType,
                    };

                    // Add fare details if available
                    if (segment.stats.fare) {
                        segmentDetails.stats.fare = {
                            price: segment.stats.fare.price,
                            currency: segment.stats.fare.currencyCode,
                            paymentMethods:
                                segment.stats.fare.paymentMethods || [],
                        };
                    }

                    // Add details for PT transport mode
                    if (segment.transportMode === "PT" && segment.details) {
                        segmentDetails.details = {
                            route: segment.details.route,
                            trip: segment.details.trip,
                            getOn: segment.details.getOn,
                            getOff: segment.details.getOff,
                        };
                    }

                    return segmentDetails;
                }),
            };
        });

        return {
            id: response.id,
            createdAt: response.createdAt,
            request: response.request,
            status: response.status,
            plannedRoutes,
        };
    },

    "plan-update.post": (response, context) => {
        return response;
    },
};
